const Modal = ({ heading, body, backBtn, onClose }) => {
  return (
    <div className="bg-gray_700/50 h-full w-full fixed top-0 bottom-0 left-0 z-50 flex items-center justify-center !px-4 lg:!px-0">
      <div className="bg-white h-[fit] w-full lg:!w-[1000px] md:rounded-lg">
        <div className="flex items-center justify-between p-2">
          <h6 className="font-semibold text-lg text-black"> {heading}</h6>

          {backBtn && (
            <button onClick={onClose}>
              <img
                src="https://drive.google.com/uc?export=view&id=1P0qToiyS3y6g2B-IM_lmCPSFALvpncew"
                alt="close icon"
              />
            </button>
          )}
        </div>

        <div>{body}</div>
      </div>
    </div>
  );
};

export default Modal;
