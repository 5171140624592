import React, { useContext } from 'react';

import { Button } from '@deposits/ui-kit-react';
import { BookingDate, SelectDropdown, Modal } from '../elements';
import Loader from './Loader';

import { useForm, Controller } from 'react-hook-form';
import { BookingContext } from '../../utils/bookingContext';
import moment from 'moment';

import useSessionStorage from '../../hooks/useSessionStorage';

const SelectBooking = () => {
  const [state, setValue] = useSessionStorage('bookingDetails', '');

  const {
    bookingDetails,
    setBookingDetails,
    setOpenBook,
    setOpenPurpose,
    scheduled,
    dates,
    setDate,
    GMT,
    bookingInfo,
    availableBookings,
    handleDateClick,
    loadingInfo,
    loadingBook,
  } = useContext(BookingContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleDateTime = handleSubmit((data) => {
    setBookingDetails({
      ...bookingDetails,
      date: moment(dates).format(),
      time: data?.timeSelected?.value,
    });

    setOpenBook(false);
    setOpenPurpose(true);
  });

  const timeObjects = availableBookings?.map((time) => ({
    label: time?.startTime,
    value: time.startTime,
    endDate: time.endTime,
  }));

  return (
    <Modal
      onClose={() => {
        setOpenBook(false);
        setValue('');
      }}
      body={
        loadingInfo ? (
          <Loader />
        ) : (
          <div className="grid sm:grid-cols-2 gap-2 !p-5 sm:!p-10 h-[550px] overflow-auto lg:overflow-hidden">
            <div className="pr-2 sm:border-r lg:overflow-auto">
              {bookingInfo?.logo && (
                <div className="h-32 w-32 relative">
                  <img
                    src={bookingInfo?.logo}
                    alt="company logo"
                    className="object-contain h-full w-full"
                  />
                </div>
              )}

              <h1 className="mt-6 mb-2 text-black text-xl font-bold">
                {bookingInfo?.name}
                </h1>
                

              {/* <p className="mt-6 mb-1 text-black text-base font-normal">
                {bookingInfo?.bio}
              </p> */}

              {bookingInfo?.description && (
                  <p className='text-sm lg:text-base text-gray-600 lg:w-11/12'>{bookingInfo?.description}</p>
              )}
            </div>

            <div className="mt-4 sm:pl-4 lg:overflow-auto">
              <p className=" text-black text-base font-normal">Step 1 of 3</p>

              <p className="mt-6 text-black text-xl font-bold">
                Select Date and time
              </p>

              <p className="mb-2 text-base font-normal text-renaissance_black">
                Time Zone
                <span className="text-mainBlue font-bold"> {GMT}</span>
              </p>

              <BookingDate
                scheduled={scheduled}
                dates={dates}
                setDate={setDate}
                handleDateClick={handleDateClick}
              />

              <div className="mt-4">
                <label className="block text-sm mb-1">Choose time</label>

                <Controller
                  control={control}
                  name="timeSelected"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <SelectDropdown
                      options={timeObjects}
                      {...field}
                      isLoading={loadingBook}
                    />
                  )}
                />

                {errors.timeSelected ? (
                  <div className="text-red-500">Choose Time</div>
                ) : null}
              </div>

              <Button
                className="mt-10 !bg-renaissance_black !w-full !border-0 !rounded-md !px-8 !py-4 !text-white"
                size="small"
                onClick={handleDateTime}
              >
                Continue
              </Button>
            </div>
          </div>
        )
      }
      backBtn={true}
    />
  );
};

export default SelectBooking;
