import React, { useContext } from 'react';

import { useForm } from 'react-hook-form';

import { Button } from '@deposits/ui-kit-react';
import DisplayForm from './DisplayForm ';

import { BookingContext } from '../../utils/bookingContext';

import useSessionStorage from '../../hooks/useSessionStorage';

import moment from 'moment';

const ChooseServices = () => {
  const {
    bookingDetails,
    setBookingDetails,
    setOpenBook,
    setOpenPurpose,
    setOpenConfirm,
    questions,
    GMT,
    bookingInfo,
    setAnswers,
  } = useContext(BookingContext);

  const hookForm = useForm();

  const { handleSubmit } = hookForm;

  const [state, setValue] = useSessionStorage('bookingDetails', '');

  const checkAndConvertFiletoUsable = (values) => {
    let newValues = {
      ...values,
    };

    const formData = new FormData();

    // Check if there is any file type among the values
    for (const key in values) {
      const fileList = values[key];

      if (fileList instanceof FileList && fileList?.length > 0) {
        // change fileInput value from list of files selected to one file
        newValues = {
          ...newValues,
          [key]: fileList[0],
        };
      }

      // convert values object to formData

      formData.append(key, newValues[key]);
    }

    const answersArray = Object.entries(newValues).map(([key, value]) => ({
      key,
      value,
    }));

    const formDataArray = answersArray;

    const mergedAnswers = questions?.map((question) => {
      const correspondingAnswer = formDataArray?.find(
        (answer) =>
          answer?.key === question?.id || answer?.key === question?._id,
      );
      return correspondingAnswer
        ? { ...question, answer: correspondingAnswer?.value }
        : question;
    });

    setBookingDetails({
      ...bookingDetails,
      answers: mergedAnswers,
    });

    setValue({
      ...bookingDetails,
      answers: mergedAnswers,
    });

    formData.append('date', bookingDetails?.date);
    formData.append('startTime', bookingDetails?.time);
    formData.append('duration', 60);
    formData.append('timezone', GMT);

    return formData;
  };

  const saveAnswers = (values) => {
    let newValues = checkAndConvertFiletoUsable(values);
    newValues =
      newValues instanceof FormData ? newValues : JSON?.stringify(newValues);

    setAnswers(newValues);

    setOpenPurpose(false);
    setOpenConfirm(true);
  };

  return (
    <div className="grid sm:grid-cols-2 gap-2 !p-5 sm:!p-10 h-[550px] overflow-auto lg:overflow-hidden">
      <div className="pr-2 sm:border-r lg:overflow-auto">
        {bookingInfo?.logo && (
          <div className="h-32 w-32 relative">
            <img
              src={bookingInfo?.logo}
              alt="company logo"
              className="object-contain h-full w-full"
            />
          </div>
        )}

        <h1 className="mt-6 mb-2 text-black text-xl font-bold">
          {bookingInfo?.name}
        </h1>

        {bookingInfo?.description && (
           <p className='text-sm lg:text-base text-gray-600 lg:w-11/12'>{bookingInfo?.description}</p>
          )}
      </div>

      <div className="mt-4 sm:pl-4 lg:overflow-auto">
        <p className=" text-black text-base font-normal">Step 2 of 3</p>

        <p className="mt-6 text-black sm:text-xl font-bold">
          Choose from our list of service
        </p>

        <div className="flex gap-x-2 sm:gap-x-4 mt-2">
          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=170WQJVMJA0gcm3HTxVvh8e2EPAJKNzHd"
              alt="calendarIcon"
              className="w-4 sm:w-5"
            />
            <p className=" text-black text-xs text-center sm:text-sm font-normal">
              {moment(bookingDetails?.date).format('ddd, MMM D')}
            </p>
          </div>

          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=16Dj06mvhf-iqHx_0i5eWvzY5VRjxMgr2"
              alt="timeIcon"
              className="w-4 sm:w-5"
            />
            <span className="block text-black text-xs sm:text-sm font-normal">
              {bookingDetails?.time} {GMT}
            </span>
          </div>
          {/* 
          <button
            className=" text-mainBlue text-xs sm:text-sm font-normal cursor-pointer"
            onClick={() => {
              setOpenPurpose(false);
              setOpenBook(true);
            }}
          >
            Change
          </button> */}
        </div>

        <form className="mt-6">
          <DisplayForm questions={questions} hookForm={hookForm} />

          <Button
            onClick={handleSubmit(saveAnswers)}
            className="mt-6 !bg-renaissance_black !w-full !border-0 !rounded-md !px-8 !py-4 !text-white"
            size="small"
          >
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ChooseServices;
