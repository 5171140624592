import React from 'react';

const RenderQuestion = ({ input, question, hookForm }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = hookForm;
  const value = watch(`${question?._id}`);

  switch (input) {
    case 'text':
      return (
        <div>
          <label className="capitalize block mb-2 text-dark-1 text-sm">
            {question?.text}
          </label>
          <input
            // name={question?.id}
            className="w-full p-2 border border-gray_300 rounded focus:outline-0 focus:border-purple_2"
            type="text"
            placeholder={question?.text}
            {...register(`${question?._id}`, {
              required: true,
            })}
          />
          {errors[question?._id] ? (
            <div className="text-red-500">Please fill this box.</div>
          ) : null}
        </div>
      );
    case 'textarea':
      return (
        <div>
          <label className="capitalize block mb-2 text-dark-1 text-sm">
            {question?.text}
          </label>
          <textarea
            rows="3"
            className="w-full p-2 border border-gray_300 rounded focus:outline-0 focus:border-purple_2"
            placeholder={question?.text}
            {...register(`${question?._id}`, {
              required: true,
            })}
          >
            {' '}
          </textarea>

          {errors[question?._id] ? (
            <div className="text-red-500">Please fill this box.</div>
          ) : null}
        </div>
      );
    case 'file':
      return (
        <div>
          <label className="capitalize block mb-2 text-dark-1 text-sm">
            {question?.text}
          </label>

          <div className="flex items-center justify-between bg-white border border-gray_300 relative rounded">
            <div className="bg-white pl-4 whitespace-nowrap text-ellipsis overflow-hidden flex-1 ">
              {value?.[0]?.name || 'No file selected...'}
            </div>

            <div className="min-w-[33%] bg-neutral_03 py-3  pl-5 pr-3  cursor-pointer text-sm font-semibold whitespace-nowra">
              Choose File
            </div>

            <input
              name={question?._id}
              type="file"
              {...register(`${question?._id}`, {
                required: true,
              })}
              className="cursor-pointer  w-full h-full absolute top-0 left-0 opacity-0"
            />
          </div>
        </div>
      );
    case 'checkbox':
      return (
        <div>
          <label className="capitalize mb-2 text-dark-1 text-sm block">
            {question?.text}
          </label>

          {question?.options.map((option, optionIdx) => (
            <div key={optionIdx} className="">
              <input
                type="checkbox"
                id={optionIdx}
                value={option.optionValue}
                name={question?._id}
                {...register(`${question?._id}`, {
                  required: true,
                })}
              />
              <span className="ml-2 text-sm capitalize">
                {option.optionText}
              </span>
            </div>
          ))}

          {errors[question?._id] ? (
            <div className="text-red-500">This is required.</div>
          ) : null}
        </div>
      );
    case 'radio':
      return (
        <div>
          <label className="capitalize mb-2 text-dark-1 text-sm">
            {question?.text}
          </label>
          {question?.options.map((option, optionIdx) => (
            <div key={optionIdx} className="">
              <label className="inline-flex items-center cursor-pointer text-md text-red-500 ">
                <input
                  type="radio"
                  value={option.optionValue}
                  id={option}
                  name={question?._id}
                  className="hidden peer"
                  {...register(`${question?._id}`, {
                    required: true,
                  })}
                />
                <div className="w-4 h-4 flex items-center justify-center border border-purple_2 rounded-full after:content-['']  after:h-1/2 after:w-1/2 after:block after:bg-purple_2 after:rounded-full after:scale-0 peer peer-checked:border peer-checked:border-purple_2 peer-checked:after:scale-100"></div>
              </label>

              <span className="ml-2 text-sm capitalize">
                {option.optionValue}
              </span>
            </div>
          ))}

          {errors[question?._id] ? (
            <div className="text-red-500">Please pick an option.</div>
          ) : null}
        </div>
      );
    case 'dropdown':
      return (
        <div>
          <label className="mb-2 capitalize text-dark-1 text-sm">
            {question?.text}
          </label>

          <select
            className="w-full p-2 border border-gray_300 rounded focus:outline-0 focus:border-purple_2"
            name={question?._id}
            {...register(`${question?._id}`, {
              required: true,
            })}
          >
            <option value="">Select</option>

            {question?.options.map((option, subindex) => (
              <option key={subindex} value={option.optionValue}>
                {' '}
                {option.optionText}{' '}
              </option>
            ))}
          </select>
          {errors[question?._id] ? (
            <div className="text-red-500">This is required.</div>
          ) : null}
        </div>
      );
    default:
      <></>;
  }
};

const DisplayForm = ({ questions, hookForm }) => {
  return (
    <ul className="grid grid-cols-1  gap-4">
      {questions?.map((question) => (
        <li key={question?._id}>
          <RenderQuestion
            input={question?.input}
            question={question}
            hookForm={hookForm}
          />
        </li>
      ))}
    </ul>
  );
};

export default DisplayForm;
