import { useMutation } from 'react-query';
import { axiosAuth } from '../utils/config/axiosConfig';

export const useGetBookingInfo = (publicKey) => {
  const mutation = useMutation(['booking-info'], (data) =>
    axiosAuth(publicKey).post(`/api/v1/organisations/get-one`, data),
  );

  return mutation;
};

export const useGetAvailableBooking = (publicKey) => {
  const mutation = useMutation(['booking-info'], (data) =>
    axiosAuth(publicKey).post(`/api/v1/bookings/available`, data),
  );

  return mutation;
};

export const useCreateBooking = (publicKey) => {
  const mutation = useMutation(['booking-info'], (data) =>
    axiosAuth(publicKey).post(`/api/v1/bookings`, data),
  );

  return mutation;
};
