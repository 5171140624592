import './App.css';
import React from 'react';
import { BookingWidget } from './components/sections';
import { BookingContextProvider } from './utils/bookingContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <BookingContextProvider>
        <ToastContainer />
        <div>
          <BookingWidget />
        </div>
      </BookingContextProvider>
    </QueryClientProvider>
  );
}

export default App;
