import React, { useContext } from 'react';

import { Button } from '@deposits/ui-kit-react';
import { BookingContext } from '../../utils/bookingContext';

import moment from 'moment';

const ConfirmBooking = () => {
  const {
    bookingDetails,
    setOpenConfirm,
    setOpenBook,
    GMT,
    creating,
    bookingInfo,
    createBooking,
  } = useContext(BookingContext);

  return (
    <div className="grid sm:grid-cols-2 gap-2 !p-5 sm:!p-10 h-[550px] overflow-auto lg:overflow-hidden">
      <div className="pr-2 sm:border-r lg:overflow-auto">
        {bookingInfo?.logo && (
          <div className="h-32 w-32 relative">
            <img
              src={bookingInfo?.logo}
              alt="company logo"
              className="object-contain h-full w-full"
            />
          </div>
        )}

        <div>
          <h1 className="mt-6 mb-2 text-black text-xl font-bold">
            {bookingInfo?.name}
          </h1>
         
          {bookingInfo?.description && (
           <p className='text-sm lg:text-base text-gray-600 lg:w-11/12'>{bookingInfo?.description}</p>
          )}
        </div>

        {/* <div className="mt-10 mb-2 text-black text-xl font-bold">
          About the booking
        </div> */}
      </div>

      <div className="sm:pl-4 lg:overflow-auto">
        <div className=" text-black text-base font-normal">Step 3 of 3</div>

        <h2 className="mt-6 text-black text-xl font-bold">
          Confirm Booking Details
        </h2>

        <div className="flex gap-x-2 sm:gap-x-4 mt-2">
          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=170WQJVMJA0gcm3HTxVvh8e2EPAJKNzHd"
              alt="calendarIcon"
              className="w-4 sm:w-5"
            />
            <span className="block text-black text-xs sm:text-sm font-normal">
              {moment(bookingDetails?.date).format('ddd, MMM D')}
            </span>
          </div>

          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=16Dj06mvhf-iqHx_0i5eWvzY5VRjxMgr2"
              alt="timeIcon"
              className="w-4 sm:w-5"
            />
            <span className=" block text-black text-xs sm:text-sm font-normal">
              {bookingDetails?.time} {GMT}
            </span>
          </div>

          {/* <button
            className=" text-mainBlue text-xs sm:text-sm font-normal cursor-pointer"
            onClick={() => {
              setOpenConfirm(false);
              setOpenBook(true);
            }}
          >
            Change
          </button> */}
        </div>

        <ul className="mt-4">
          {bookingDetails?.answers?.map((answer) => (
            <li className="mb-3" key={answer?._id}>
              <span className="font-medium text-base block">
                {answer?.text}
              </span>
              <span className="text-sm text-gray-600">{answer?.answer}</span>
            </li>
          ))}
        </ul>

        <Button
          className="mt-10 !bg-mainBlue !w-full !border-0 !rounded-md !px-8 !py-4 !text-white"
          size="small"
          onClick={createBooking}
        >
          {creating ? 'Creating...' : 'Confirm Booking'}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmBooking;
