import { useState } from 'react';

const useSessionStorage = (key, initialValue) => {
  const [state, setState] = useState(() => {
    try {
      const value = sessionStorage.getItem(key);

      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      return error;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(state) : value;
      sessionStorage.setItem(key, JSON.stringify(valueToStore));
      setState(value);
    } catch (error) {
      return error;
    }
  };

  return [state, setValue];
};

export default useSessionStorage;
