import React from 'react';
import { Modal } from '../elements';

const Loader = () => {
  return (
    <div>
      <Modal
        body={
          <div className=" p-5 sm:p-10 h-[550px] flex justify-center items-center overflow-auto">
            <div>
              <img
                src="https://drive.google.com/uc?export=view&id=1TFAET-qaNdX2nLqxvnlG24sdrDGT3uwz"
                alt="loading"
              />
            </div>
          </div>
        }
      />
    </div>
  );
};

export default Loader;
