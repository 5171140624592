import React, { useContext, useEffect, useState } from "react";

import { BookingContext } from "../../utils/bookingContext";
import { Modal } from "../elements";
import { ChooseServices, CloseBooking, ConfirmBooking, SelectBooking } from ".";

import useSessionStorage from "../../hooks/useSessionStorage";

const BookingWidget = () => {
  const [state, setValue] = useSessionStorage("bookingDetails", "");

  const {
    openBook,
    setOpenBook,
    openPurpose,
    setOpenPurpose,
    openConfirm,
    setOpenConfirm,
    openCloseBook,
    setOpenCloseBook,
  } = useContext(BookingContext);

  const showModal = () => {
    setOpenBook(true);
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const elements = document.getElementsByClassName("ril-widget-trigger");

    if (
      document
        .getElementById("ril-booking-root")
        .getAttribute("data-hide-button")
    ) {
      setShowButton(false);
    } else {
      setShowButton(true);
    }

    Array.from(elements).forEach(function (element) {
      element.addEventListener("click", showModal);
    });

    return () => {
      Array.from(elements).forEach(function (element) {
        element.removeEventListener("click", showModal);
      });
    };
  },[]);

  return (
    <div className="ril-widget_scrollbar font-openSans">
      {showButton && (
        <button
          className="flex gap-x-2 bg-renaissance_black border-0 px-5 py-3 text-white rounded-lg cursor-pointer"
          onClick={() => setOpenBook(true)}
        >
          <img
            src="https://drive.google.com/uc?export=view&id=1N61-mle8FZ4n-B0TlaDl6dS37u8ur03m"
            alt="book icon"
          />
          <p>Book an appointment</p>
        </button>
      )}

      {openBook && <SelectBooking />}

      {openPurpose && (
        <Modal
          onClose={() => {
            setOpenPurpose(false);
            setValue("");
          }}
          body={<ChooseServices />}
          backBtn={true}
        />
      )}

      {openConfirm && (
        <Modal
          onClose={() => {
            setOpenConfirm(false);
            setValue("");
          }}
          body={<ConfirmBooking />}
          backBtn={true}
        />
      )}

      {openCloseBook && (
        <Modal
          onClose={() => {
            setOpenCloseBook(false);
            setValue("");
          }}
          body={<CloseBooking />}
          backBtn={true}
        />
      )}
    </div>
  );
};

export default BookingWidget;
