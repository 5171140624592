import React from 'react';
import Select from 'react-select';

const SelectDropdown = React.forwardRef(function SelectDropdownt(
  { defaultValue, onChange, styles, isLoading, options, ...rest },
  ref,
) {
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      fontSize: '14px',
      textTransform: 'capitalize',
      zIndex: 9999999,
    }),

    placeholder: (provided) => ({
      ...provided,
      color: '#212120',
    }),

    control: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      height: '50px',
      background: 'transparent',
      border: `1px solid ${state.isFocused ? '#6145D0' : '#B8C4CE '}`,
      outline: 0,
      boxShadow: 0,
      WebkitBoxShadow: 0,
      MozBoxShadow: 0,
      color: '#111',
      textTransform: 'capitalize',
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? '#6145D0'
        : state.isFocused
        ? 'rgba(180, 163, 248, 0.14)'
        : '',
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  return (
    <Select
      defaultValue={defaultValue}
      onChange={onChange}
      styles={customStyles}
      options={options}
      components={{
        IndicatorSeparator: () => null,
        LoadingIndicator: () =>
          isLoading && (
            <div className="text-sm text-dark-600">Loading available time</div>
          ),
      }}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
});

export default SelectDropdown;
