import moment from 'moment';

export const formatDate = (date, formatType) => {
  return moment(date).format(formatType);
};

// export const getInitials = (name) => {
//   return name.match(/(\b\S)?/g).join('');
// };

export const getInitials = (name) => {
  return name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('');
};

export const DDMMYYYY = (date) => {
  return moment(date).format('DD MMM, YYYY');
};

export const ratingPercentage = (rating) => {
  return ((Number(rating) / 5) * 100).toFixed();
};

//converts file size to kb, mb, etc
export const convertFileSize = (x) => {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + units[l];
};

