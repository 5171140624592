import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const axiosAuth = (public_key) =>
  axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
      publishable_key: public_key,
    },
  });
