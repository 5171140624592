import { useState, createContext, useEffect } from 'react';
import moment from 'moment';
import {
  useGetBookingInfo,
  useGetAvailableBooking,
  useCreateBooking,
} from '../hooks/useBooking';

import useSessionStorage from '../hooks/useSessionStorage';

import { toast } from 'react-toastify';

export const BookingContext = createContext();

export const BookingContextProvider = (props) => {
  const [publicKey, setPublicKey] = useState('');
  const [state, setValue] = useSessionStorage('bookingDetails', '');

  const [bookingDetails, setBookingDetails] = useState({
    date: '',
    time: '',
    answers: null,
  });

  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(null);
  const [availableBookings, setAvailableBookings] = useState([]);
  const [loadingBook, setLoadingBook] = useState(false);

  const [bookingInfo, setBookingInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const [creating, setCreating] = useState(false);

  const [dates, setDate] = useState(new Date());

  // modal states
  const [scheduled, setScheduled] = useState([]);
  const [openBook, setOpenBook] = useState(false);
  const [openPurpose, setOpenPurpose] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [openCloseBook, setOpenCloseBook] = useState(false);

  // GMT calculation
  const offsetMinutes = new Date().getTimezoneOffset();
  const offsetSign = offsetMinutes > 0 ? '-' : '+';
  const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
  const GMT = `GMT ${offsetSign}${offsetHours}`;

  useEffect(() => {
    const public_key = document
      .getElementById('ril-booking-root')
      .getAttribute('data-public-key');

    setPublicKey(public_key);
  }, []);

  // booking mutations
  const { mutate: mutateGetBookingInfo } = useGetBookingInfo(publicKey);
  const { mutate: mutateAvailableBooking } = useGetAvailableBooking(publicKey);
  const { mutate: mutateCreateBooking } = useCreateBooking(publicKey);

  const currentDate = new Date();
  const currentHours = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();
  const currentTimeInMinutes = currentHours * 60 + currentMinutes;

  const filterPastTime = (arr) => {
    const filteredTimeSlots = arr?.filter(({ startTime }) => {
      const [startHours, startMinutes] = startTime.split(':');
      const startTimeInMinutes =
        parseInt(startHours, 10) * 60 + parseInt(startMinutes, 10);
      return startTimeInMinutes >= currentTimeInMinutes;
    });

    return filteredTimeSlots;
  };

  // retrieve booking information
  useEffect(() => {
    const getBookingInfo = async () => {
      const time = {
        timezone: GMT,
      };
      setLoadingInfo(true);
      mutateGetBookingInfo(time, {
        onSuccess: (data) => {
          setBookingInfo(data?.data?.doc);
          setQuestions(data?.data?.form?.fields);
          setAvailableBookings(filterPastTime(data?.data?.availableBookings));
          setLoadingInfo(false);
        },
        onError: (err) => {
          setLoadingInfo(false);
          toast.error(err?.response?.data?.message);
        },
      });
    };

    if (publicKey) {
      getBookingInfo();
    }
  }, [GMT, mutateGetBookingInfo, publicKey]);

  //retrieve active booking
  const handleDateClick = async (date) => {
    setLoadingBook(true);
    const payload = {
      date: moment(date).format(),
      timezone: GMT,
    };
    //  setLoadingInfo(true);
    mutateAvailableBooking(payload, {
      onSuccess: (data) => {
        setLoadingBook(false);
        if (
          moment(currentDate).format('DD-MM-YYYY') ===
          moment(date).format('DD-MM-YYYY')
        ) {
          setAvailableBookings(filterPastTime(data?.data?.data));
        } else {
          setAvailableBookings(data?.data?.data);
        }
      },
      onError: (err) => {
        setLoadingBook(false);
        toast.error(err?.response?.data?.message);
      },
    });
  };

  //create booking
  const createBooking = async () => {
    setCreating(true);

    mutateCreateBooking(answers, {
      onSuccess: (data) => {
        setCreating(false);

        // update session storage booking with the latest data
        setValue(data?.data?.doc);

        setOpenConfirm(false);
        setOpenCloseBook(true);
      },
      onError: (err) => {
        setCreating(false);
        toast.error(err?.response?.data?.message);
      },
    });
  };

  return (
    <BookingContext.Provider
      value={{
        GMT,
        bookingDetails,
        setBookingDetails,
        scheduled,
        setScheduled,
        questions,
        dates,
        setDate,
        openBook,
        setOpenBook,
        openPurpose,
        setOpenPurpose,
        openConfirm,
        setOpenConfirm,
        openLoading,
        setOpenLoading,
        openCloseBook,
        setOpenCloseBook,
        bookingInfo,
        loadingInfo,
        handleDateClick,
        createBooking,
        availableBookings,
        setAvailableBookings,
        loadingBook,
        creating,
        answers,
        setAnswers,
      }}
    >
      {props.children}
    </BookingContext.Provider>
  );
};
