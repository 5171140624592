import React, { useContext } from 'react';

import { Button } from '@deposits/ui-kit-react';
import { BookingContext } from '../../utils/bookingContext';

import useSessionStorage from '../../hooks/useSessionStorage';

import moment from 'moment';

const CloseBooking = () => {
  const { setOpenCloseBook, bookingInfo, GMT } = useContext(BookingContext);

  const [state, setValue] = useSessionStorage('bookingDetails', '');

  const closeBooking = () => {
    setOpenCloseBook(false);
    setValue('');
  };

  return (
    <div className="grid sm:grid-cols-2 gap-2 !p-5 sm:!p-10 h-[550px] overflow-auto lg:overflow-hidden">
      <div className="pr-2 sm:border-r lg:overflow-auto">
        <div className="mt-6 text-black text-xl font-bold">Booking Details</div>

        <div className="flex gap-x-2 sm:gap-x-4 mt-2">
          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=170WQJVMJA0gcm3HTxVvh8e2EPAJKNzHd"
              alt="calendarIcon"
              className="w-4 sm:w-5"
            />
            <span className="block text-black text-xs sm:text-sm font-normal">
              {moment(state?.date).format('ddd, MMM D')}
            </span>
          </div>

          <div className="flex gap-x-1">
            <img
              src="https://drive.google.com/uc?export=view&id=16Dj06mvhf-iqHx_0i5eWvzY5VRjxMgr2"
              alt="timeIcon"
              className="w-4 sm:w-5"
            />
            <span className="block text-black text-xs sm:text-sm font-normal">
              {state?.startTime} {GMT}
            </span>
          </div>
        </div>
       
       
      </div>

      <div className="mt-4 sm:pl-4 lg:overflow-auto">
        <div className="flex justify-center items-center">
          <img
            src="https://drive.google.com/uc?export=view&id=1wH2ri_yQvwmjjLD_oEgPBCFQGgtUzOCW"
            alt="ril logo"
          />
          <p className=" text-[#158957] text-lg font-semibold">
            Booking Confirmed
          </p>
        </div>

        <p className="mt-6 text-black text-center text-base font-normal">
          You have scheduled a booking session with{' '}
          <span className="font-semibold">{bookingInfo?.name}</span>
        </p>

        <p className="mt-6 text-black text-center text-base font-normal">
          A confirmation email has been sent to you on{' '}
          <span className="font-semibold">
            {state?.details?.['Email Address']}
          </span>
        </p>

        <Button
          className="mt-10 !bg-mainBlue !w-full !border-0 !rounded-md !px-8 !py-4 !text-white"
          size="small"
          onClick={closeBooking}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default CloseBooking;
