import Calendar from 'react-calendar';
import './exploreCalendar.css';

import { formatDate } from '../../helpers/functions';

const BookingDate = ({ handleDateClick, scheduled, dates, setDate }) => {
  return (
    <>
      <article className="upcoming-calendar">
        <Calendar
          onChange={setDate}
          value={dates}
          next2Label={null}
          prev2Label={null}
          minDate={new Date()}
          formatShortWeekday={(locale, date) =>
            ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
          }
          tileClassName={({ date, view }) => {
            if (
              scheduled?.find(
                (item) =>
                  formatDate(item?.date, 'DD-MM-YYYY') ===
                  formatDate(date, 'DD-MM-YYYY'),
              )
            ) {
              return 'highlights';
            }
          }}
          onClickDay={(date) => handleDateClick(date)}
        />
      </article>
    </>
  );
};

export default BookingDate;
